<template>
  <iframe
    :id="iframeId"
    title="NoteForms testimonial"
    height="500px"
    loading="lazy"
    :src="'https://embed-v2.testimonial.to/w/notionforms?theme=light&card=base&loadMore=on&initialCount='+initialCount+'&tag=all'"
    frameBorder="0"
    scrolling="no"
    width="100%"
  />
</template>

<script>

export default {
  props: {
    featuresOnly: {
      type: Boolean,
      default: false
    },
    initialCount: {
      type: Number,
      default: 8
    }
  },

  data: () => ({
    iframeId: 'testimonialto-carousel-all-notionforms'
  }),

  computed: {},

  mounted () {
    this.loadScript()
  },

  methods: {
    loadScript () {
      if (import.meta.server)
        return
      const script = document.createElement('script')
      script.setAttribute(
        'src',
        'https://testimonial.to/js/iframeResizer.min.js'
      )
      script.setAttribute('defer', 'defer')
      script.addEventListener('load', () => {
        window.iFrameResize(
          {
            log: false,
            checkOrigin: false
          },
          '#' + this.iframeId
        )
      })
      document.head.appendChild(script)
    }
  }
}
</script>


